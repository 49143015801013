import Vue from 'vue'
import App from './App.vue';
import router from './router'; // 路由
import store from './store/index'; // vuex
import {v3} from '@/api'; // vuex
import VueI18n from 'vue-i18n';
// import ElementUI from 'element-ui';
import { Dialog, Upload } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/font/iconfont.css';
import { messages } from '@/assets/js/data'; // 语言包
import findKey from 'lodash/findKey.js'; // 多语言相关组件
import { getOrigin } from '@/utils/getUserAgent'; // 全局注册组件
import Cookies from 'js-cookie';
import { Popup, Toast, ImagePreview,Lazyload } from 'vant';
import axios from 'axios';
import 'vant/lib/index.css';
import '@/assets/css/tailwind.css';
import '@/assets/css/reset.css';
import '@/utils/global'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'hammerjs';
// import panZoom from 'vue-panzoom'
Vue.prototype.$Toast = Toast
Vue.prototype.$ImagePreview = ImagePreview
// install plugin
// Vue.use(panZoom);
// Vue.use(Vant);
// import 'lib-flexible'

Vue.use(Lazyload)
Vue.config.productionTip = false
Vue.use(Popup);
// Vue.use(ImagePreview);
Vue.use(VueI18n);
Vue.prototype.$cookies = Cookies; // 设置cookie
Vue.prototype.$store = store; // vuex
Vue.prototype.$v3 = v3; // vuex

Vue.use(Upload);
Vue.use(Dialog);
const commit = Vue.prototype.$store;
// 处理域名
if (window.location.host.includes('.com')) { // 设置获取主域名
  const host = window.location.host.split('.').splice(1, 2);
  commit.commit('base/sethost', host.join('.'));
}
let getdata = {};
const appagentparam = getOrigin(); // app进来的数据test
// const vux = commit.state.base;
// console.log(appagentparam, 8866555)


let lang = ''; // 定义语言
const langList = store.state.base.langList;
// console.log('langList: ', langList);
lang = window.location.href.replace('http://', '').replace('https://', '').split('/')[1].split('?')[0];
console.log('lang: ', lang);
// ---取浏览器语言
let navlang = navigator.language.split(',')[0].toLowerCase()
console.log('navlang: ', navlang);
if (navlang != 'zh-cn' && navlang.includes('-')) {
  navlang = navlang.split('-')[0]
}
if (!langList.includes(navlang)) navlang = 'en'
console.log('store.state.base.langList.includes(lang): ', store.state.base.langList.includes(lang));

// ---------------------------------
lang = lang && store.state.base.langList.includes(lang) ? lang.toLocaleLowerCase() : navlang ? navlang : 'en';
if (lang === 'zh-tw' || lang === 'zh-hk') {
  lang = 'zh';
  window.location.href = `/${lang}`;
}
if (appagentparam?.BasicData) { // 判断如果为app传入contentcookie则使用app数据
  Object.assign(getdata, appagentparam);
  const userInfo = {
    userId: getdata?.userId || '',
    nick: getdata?.nick || '',
    avatar: getdata?.avatar || ''
  };
  console.log('userInfo: app 传值', userInfo);
  commit.commit('base/setisApp', true);
  commit.commit('base/setUser', userInfo);

  const navHeight = (parseFloat(getdata.navHeight)).toFixed(2);

  commit.commit('base/getNavHeight', navHeight); // 设置为APP
  commit.commit('base/setuserCountInfo', {
    flag: getdata?.falg,
    code: getdata?.phonecode
  }); // 设置
  commit.commit('base/setwikinum', getdata?.wikinum); // 设置天眼号
  commit.commit('base/setCountryCode', getdata?.countrycode) // 设置国家
  commit.commit('base/setMyCountry', getdata?.myCountry) // 设置ip国家

  // if (getdata.enterpriseCode) { // 是否有code
  //   commit.commit('base/setEnterpriseCode', getdata.enterpriseCode); // 设置企业code
  // } else {
  //   commit.commit('base/setEnterpriseCode', ''); // 设置企业code
  // }


  commit.commit('base/setLangCode', getdata.language.toLowerCase()); // 存入当前语言
  lang = getdata.language.toLowerCase();
} else{
  commit.commit('base/setLangCode', lang); // 存入当前语言
  new Promise((resolve) => {
    try {
      axios({
        url: 'https://exportedbase.interface003.com/ip',
        timeout: 10000,
      })
        .then(async (result) => {
          const ipinfo = result && result.data && result.data.result ? result.data.result : {}; // 获取用户ip，国家,城市
          
          let country = ipinfo.country_code.toLowerCase() || 'cn';
          let myCountry = ipinfo.country_code.toLowerCase() || 'cn';
  
          // 获取国家数据
          let countryArr = [];
          if (lang === 'zh-cn' || lang === 'zh-tw' || lang === 'zh-hk' || lang === 'zh') {
            countryArr = require('@/assets/json/areaCodeCN.json');
          } else {
            countryArr = require('@/assets/json/areaCodeEn.json');
          }
          let countryInfo = {};
          countryArr.forEach((item) => {
            item.data.forEach((itemS) => {
              if (country === itemS.twoCharCode.toLowerCase() || country === itemS.countryCode) {
                // 用户选择得国家
                countryInfo = itemS;
                country = itemS.twoCharCode.toLowerCase();
              }
              if (myCountry === itemS.twoCharCode.toLowerCase() || myCountry === itemS.countryCode) {
                // 真实国家
                myCountry = itemS.twoCharCode.toLowerCase();
              }
            });
          });
          country = country === 'gb' ? 'uk' : country;
          store.commit('base/setCountryCode', country); // 当前用户所在的国家
          store.commit('base/setMyCountry', myCountry); // 当前用户所在的国家(不变的值)
          store.commit('base/getIp', ipinfo.ip); // 用户ip
          store.commit('base/setInfo', countryInfo); // 获取国家code，国家名称，国家代码，国旗
          store.commit('base/setuserCountInfo', countryInfo); // 用户中心获取国家code，国家名称，国家代码，国旗
          resolve(country);
        })
        .catch(() => {
          console.log('ip错误');
          
          // ip报错给出默认值继续往下执行
          // 获取国家数据
          let countryArr = [];
          if (lang === 'zh-cn' || lang === 'zh-tw' || lang === 'zh-hk' || lang === 'zh') {
            countryArr = require('@/assets/json/areaCodeCN.json');
          } else {
            countryArr = require('@/assets/json/areaCodeEn.json');
          }
          let country = 'cn';
          let myCountry = 'cn';
  
          let countryInfo = {};
          countryArr.forEach((item) => {
            item.data.forEach((itemS) => {
              if (country === itemS.twoCharCode.toLowerCase() || country === itemS.countryCode) {
                // 用户选择得国家
                countryInfo = itemS;
                country = itemS.twoCharCode.toLowerCase();
              }
              if (myCountry === itemS.twoCharCode.toLowerCase() || myCountry === itemS.countryCode) {
                // 真实国家
                myCountry = itemS.twoCharCode.toLowerCase();
              }
            });
          });
          store.commit('base/setCountryCode', country); // 设置国家
          store.commit('base/setMyCountry', myCountry); // 设置真实国家
          store.commit('base/getIp', '114.114.114.114'); // 设置用户ip
          store.commit('base/setInfo', countryInfo); // 获取国家code，国家名称，国家代码，国旗
          store.commit('base/setuserCountInfo', countryInfo); // 用户中心获取国家code，国家名称，国家代码，国旗
          resolve(country);
        });
    } catch (error) {
      // console.log(error, '---main---1');
    }
  })
  
}

if (getdata.userId) commit.commit('base/setUserid', getdata.userId); // 设置userid

// ...对数据进行存储



const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true
});
VueI18n.prototype.translation = function (value) {
  let en = require('@/assets/languages/TXT_zh-CN.json');
  let val = findKey(en, function (o) {
    return o === value;
  });
  if (!val) {
    return value + '(前端未翻译)';
  }
  return this.$t(val);
};
Vue.prototype.$v = i18n.translation; // i18n
document.querySelector('html').setAttribute('lang', lang);
i18n.location = lang;
if (lang === 'ar') {
  document.querySelector('html').setAttribute('dir', 'rtl');
} else {
  document.querySelector('html').setAttribute('dir', 'ltr');
}
new Vue({
  router,
    store,
    i18n,
  render: h => h(App),
}).$mount('#app')
